@import './global.scss';

html, body {
  height: 100%;
  margin: 0;
  font-size: $font-size-normal;
}
p, ul li {
  font-size: $font-size-normal; 
}
nav a {
  font-size: $font-size-normal;
}

#root {
  height: inherit;
}

.container-wrapper {
  min-height: 100%;
  margin-bottom: -$footer-height;
  .page {
    padding: $indent-normal;
  }
}

.footer, .push {
  height: $footer-height;
}