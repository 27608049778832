// colors
$white: #fff;
$orange: #f1991b;
$gray: #343a40;

// fonts
$font-size-large: 22px;
$font-size-normal: 18px;
$font-size-small: 14px;
$font-weight-bold: 600;

// sizes
$footer-height: 80px;

// indents
$indent-xsmall: 8px;
$indent-small: 24px;
$indent-normal: 32px;