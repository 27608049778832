@import '../global.scss';

.projects-content-wrapper {
  .sidebar {
    margin-bottom: $indent-small;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin: 10px 0;
        a {
          color: $gray;
          text-decoration: underline;
          &.active {
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }
  .projects-content {
    
  }
}